.guide-container {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
}
.guide {
    &__inner {
        width: 100%;
        max-width: 840px;
        margin-inline: auto;
    }
    &__anchor-link {
        @include content-anchor-nav;
        @include media-down(md) {
            margin-bottom: 0;
        }
    }
    &__title {
        @include c-local-title(center);
    }
    &__lead {
        margin-bottom: 25px;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-up(md) {
            text-align: center;
        }
    }
}
.guide-price {
    overflow: hidden;
    padding-top: 70px;
    padding-left: map-get($spacers, 2);
    padding-right: map-get($spacers, 2);
    @include media-down(md) {
        padding-top: 50px;
    }
    .guide__title {
        margin-bottom: 40px;
        @include media-down(md) {
            margin-bottom: 50px;
        }
    }
    &__container {
        position: relative;
        margin-bottom: 43px;
        padding: 26px;
        background-color: #f5f7fa;
        border-radius: 10px;
        @include media-down(lg) {
            border-radius: 0;
        }
        @include media-down(md) {
            margin-bottom: 80px;
        }
        @include media-down(sm) {
            padding: 40px 30px 30px;
        }
        [class*='fig'] {
            position: absolute;
        }
        .fig1 {
            top: -53px;
            left: 10px;
            @include media-down(lg) {
                top: -36px;
                left: 15px;
                width: 74px;
                height: auto;
            }
        }
        .fig2 {
            bottom: -11px;
            right: -58px;
            @include media-down(lg) {
                bottom: -26px;
                right: 7px;
                width: 130px;
                height: auto;
            }
        }
        .title {
            font-size: calc(18 / 16 * $font-size-base);
            font-family: 'Zen Maru Gothic', $font-family-sans-serif;
            font-weight: 700;
            text-align: center;
            color: $secondary;
            letter-spacing: 0.2em;
            @include media-down(sm) {
                margin-bottom: 25px;
            }
        }
        .text {
            margin: 0;
            font-size: calc(12 / 16 * $font-size-base);
            font-weight: bold;
            color: $secondary;
            text-align: center;
            letter-spacing: 0.12em;
            @include media-down(sm) {
                font-size: calc(13 / 16 * $font-size-base);
                text-align: left;
            }
        }
    }
    &__list {
        margin-bottom: 25px;
        @include media-up(sm) {
            display: flex;
            justify-content: center;
            gap: 20px;
        }
    }
    &__item {
        display: flex;
        align-items: flex-end;
        width: 273px;
        max-width: calc((100% - 20px) / 2);
        padding-bottom: 15px;
        border-bottom: solid 2px $secondary;
        @include media-down(sm) {
            width: 100%;
            max-width: 100%;
            margin-bottom: 15px;
            padding-left: 8px;
            padding-bottom: 11px;
        }
        span {
            display: inline-block;
            color: $secondary;
            line-height: 1;
        }
        .smallText {
            margin-right: 17px;
            font-size: calc(14 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.12em;
            @include media-down(sm) {
                min-width: 95px;
            }
        }
        .priceText {
            transform: translateY(3px);
            font-size: calc(39 / 16 * $font-size-base);
            font-family: 'Ubuntu', $font-family-sans-serif;
            // font-weight: 500;
            font-weight: 700;
            letter-spacing: 0.08em;
        }
    }
    &__text {
        margin-bottom: 20px;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #000;
        letter-spacing: 0.12em;
        @include media-down(sm) {
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
}
@keyframes scroll_arrow {
    0% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(12px);
    }

    20% {
        transform: translateX(0);
    }
}
.guide-course {
    &__title {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        max-width: 840px;
        margin: 0 auto 35px;
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(22 / 16 * $font-size-base);
        color: #fff;
        letter-spacing: 0.12em;
        line-height: 1;
        @include media-up(lg) {
            border-radius: 10px;
        }
        @each $key, $color in $base_color_ary {
            .#{$key} & {
                background-color: $color;
            }
        }
        .icon {
            position: absolute;
            bottom: -6px;
            @each $key, $color in $base_color_ary {
                .#{$key} & {
                    fill: $color;
                }
            }
            @include media-down(sm) {
                bottom: -5px;
                width: 15px;
                height: auto;
            }
        }
    }
    &__subTitle {
        @include c-sub-title();
        &._single {
            padding-left: 0;
            &::before {
                display: none;
            }
        }
    }
    &__text {
        margin-bottom: 25px;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            line-height: calc(28 / 15);
        }
        &._indent {
            padding-left: 1em;
            text-indent: -1em;
            @include media-down(sm) {
                font-size: calc(12 / 16 * $font-size-base);
                padding-left: 0;
                text-indent: 0;
                line-height: calc(19 / 12);
            }
        }
        &._narrowly {
            letter-spacing: 0.09em;
        }
    }
    &__tyukiText {
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(sm) {
            font-size: calc(12 / 16 * $font-size-base);
            line-height: calc(19 / 12);
        }
    }
    &__table-wrap {
        @include media-down(sm) {
            position: relative;
            &::before {
                content: '右にscroll';
                display: block;
                margin-left: auto;
                margin-bottom: 0.8rem;
                padding-right: 40px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.33 7.06"><defs><style>.cls-1{fill:none;stroke:#373738;stroke-linecap:round;stroke-linejoin:round;}</style></defs><g id="_デザイン"><polyline class="cls-1" points=".5 6.56 37.83 6.56 32.37 .5"/></g></svg>'
                    )
                    100%/38px 7px no-repeat;
                font-size: calc(12 / 16 * $font-size-base);
                letter-spacing: 0.13em;
                text-align: right;
                animation: scroll_arrow 3s ease-in-out infinite forwards;
            }
        }
    }
    &__table-inner {
        @include media-down(sm) {
            overflow-x: auto;
            white-space: nowrap;
        }
        .guide-course__table {
            @include media-down(sm) {
                width: 600px;
            }
        }
    }
    &__table {
        @include table-typeA;
    }
    &__btnwrap {
        display: flex;
        justify-content: center;
        margin-top: 45px;
        margin-bottom: 115px;
        @include media-down(md) {
            margin-top: 30px;
            margin-bottom: 90px;
        }
    }
    &__btnwrap2 {
        display: flex;
        justify-content: center;
        margin-top: 45px;
        @include media-down(md) {
            margin-top: 30px;
        }
    }
    &__ghostBtn {
        @include c-ghost-button();
    }
    &__btn {
        @include c-round-button-reverse();
        background-color: #00a2d9;
        &.center {
            justify-content: center;
            padding-left: 0;
        }
    }
    &.adult &__btn {
        width: 280px !important;
    }
}
.guide-join {
    margin-bottom: 90px;
    padding-top: 85px;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    background: url(/assets/img/webp/line-yoko-blue@2x.webp) repeat-x top left /
        auto 1px;
    @include media-down(md) {
        margin-bottom: 33px;
        padding-top: 70px;
    }
    &__list {
        margin: 45px 0 0;
        padding: 0;
        list-style-type: none;
        @include media-down(md) {
            margin-top: 20px;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        padding-top: 13px;
        padding-bottom: 13px;
        border-radius: 10px;
        @include media-down(md) {
            border-radius: 8px;
        }
        &:nth-of-type(odd) {
            background-color: #f5f7fa;
        }
        .num {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            font-size: calc(25 / 16 * $font-size-base);
            font-family: 'Ubuntu', $font-family-sans-serif;
            font-weight: 700;
            color: $secondary;
            letter-spacing: 0.08em;
            @include media-down(md) {
                width: 62px;
                font-size: calc(19 / 16 * $font-size-base);
            }
        }
        &__content {
            width: 85%;
            padding: 8px 32px;
            background: url(/assets/img/webp/line-tate-blue@2x.webp) top
                left/1.5px auto repeat-y;
            @include media-down(md) {
                width: calc(100% - 62px);
                padding: 4px 15px;
            }
            .title {
                margin-bottom: 8px;
                font-size: calc(18 / 16 * $font-size-base);
                font-weight: bold;
                color: $secondary;
                letter-spacing: 0.12em;
                line-height: calc(20 / 15);
                @include media-down(md) {
                    font-size: calc(15 / 16 * $font-size-base);
                }
            }
            .text {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                font-weight: bold;
                color: $text-color;
                letter-spacing: 0.12em;
                line-height: calc(13 / 10);
                @include media-down(md) {
                    font-size: calc(13 / 16 * $font-size-base);
                }
            }
            .tyuki {
                margin: 8px 0 0;
                font-size: calc(14 / 16 * $font-size-base);
                color: $text-color;
                letter-spacing: 0.12em;
                line-height: calc(13 / 10);
                @include media-down(md) {
                    font-size: calc(12 / 16 * $font-size-base);
                }
            }
        }
    }
}
.guide-bnr {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
            transition: $transition-base;
            display: block;
            &:not(:last-of-type) {
                margin-bottom: 27px;
                @include media-down(md) {
                    margin-bottom: 15px;
                }
            }
            &:last-of-type {
                margin-bottom: 110px;
                @include media-down(md) {
                    margin-bottom: 70px;
                }
            }
            @media (hover: hover) {
                &:hover,
                &:focus {
                    opacity: 0.8;
                }
            }
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
    .rwd002-block-freearea {
        margin: 0;
        padding: 0;
    }
}
.guide-bus {
    padding-top: 85px;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    background: url(/assets/img/webp/line-yoko-blue@2x.webp) repeat-x top left /
        auto 1px;
    @include media-down(md) {
        padding-top: 70px;
    }
    &__table {
        @include table-base;
    }
    &__text {
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        color: $text-color;
        letter-spacing: 0.11em;
        line-height: calc(32 / 14);
        @include media-down(md) {
            font-size: calc(12 / 16 * $font-size-base);
            line-height: calc(19 / 12);
        }
    }
}
