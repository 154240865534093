@function svg-right-angle($theme: $primary) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
    );
}

@function svg-bottom-angle($theme: $body-color) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>'
    );
}

.btn-icon-right-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 1em;
        width: 1em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svg-right-angle();
    }
    &:hover:after,
    &:focus:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-right-angle-white {
    @extend .btn-icon-right-angle;
    &:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-bottom-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 0;
        width: 2em;
        height: 0.6em;
        transition: all 0.5s ease-in-out;
        background: center / contain no-repeat;
        background-image: svg-bottom-angle();
        transform: rotateX(180deg);
    }
    &.collapsed:after {
        transform: rotateX(0deg);
    }
}

@mixin c-round-button() {
    transition: $transition-base;
    position: relative;
    display: flex;
    align-items: center;
    width: 225px;
    height: 60px;
    padding-left: 38px;
    font-size: calc(14 / 16 * $font-size-base);
    font-weight: bold;
    color: #fff !important;
    text-decoration: none !important;
    letter-spacing: 0.12em;
    background-color: #006ab4;
    border-radius: 100em;
    @include media-down(md) {
        width: 215px;
        height: 45px;
        margin: auto;
        padding-left: 34px;
    }
    &::before {
        content: '';
        position: absolute;
        right: 25px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#339fd4;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
            )
            right center / 100% auto no-repeat;
        @include media-down(md) {
            right: 16px;
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            background-color: #003876;
        }
    }
}

@mixin c-round-button-reverse() {
    transition: $transition-base;
    position: relative;
    display: flex;
    align-items: center;
    width: 323px;
    height: 60px;
    padding-left: 22px;
    font-size: calc(14 / 16 * $font-size-base);
    font-weight: bold;
    color: #fff !important;
    text-decoration: none !important;
    letter-spacing: 0.12em;
    background-color: #00a2d9;
    border-radius: 100em;
    @include media-down(md) {
        width: 325px;
        height: 58px;
        margin: auto;
        padding-left: 35px;
        font-size: calc(13 / 16 * $font-size-base);
    }
    &::before {
        content: '';
        position: absolute;
        right: 25px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background: svgUrlFunc(
                '<svg id="icon-list-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#0a59a0;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
            )
            right center / 100% auto no-repeat;
        @include media-down(md) {
            right: 18px;
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            background-color: #003876;
        }
    }
}

@mixin c-ghost-button() {
    transition: $transition-base;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 9px;
    font-size: calc(14 / 16 * $font-size-base);
    font-weight: bold;
    color: #00A2D9 !important;
    text-decoration: none !important;
    letter-spacing: 0.12em;
    line-height: calc(20 / 14);
    border: solid 1px #00A2D9;
    border-radius: 4px;
    @include media-down(md) {
        border-radius: 3px;
    }
    @include media-down(sm) {
        text-align: center;
    }
    &::after {
        content: '';
        transform: rotate(90deg);
        display: inline-block;
        // position: absolute;
        // right: 25px;
        // top: 0;
        // bottom: 0;
        width: 18px;
        height: 18px;
        margin-left: 30px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#339fd4;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
            )
            right center / 100% auto no-repeat;
        @include media-down(sm) {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 9px;
            margin: auto;
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            color: #fff !important;
            background-color: $primary;
            &::after {
                background: svgUrlFunc(
                        '<svg id="icon-list-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#0a59a0;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
                    )
                    right center / 100% auto no-repeat;
            }
        }
    }
}

@mixin c-more-button() {
    transition: $transition-base;
    position: relative;
    width: max-content;
    padding: 7px 25px 7px 0;
    font-size: calc(14 / 16 * $font-size-base);
    font-weight: bold;
    color: $text-color;
    letter-spacing: 0.12em;
    line-height: 1;
    background: none;
    border: none;
    border-bottom: solid 1px $primary;
    @include media-down(md) {
        font-size: calc(13 / 16 * $font-size-base);
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            color: $primary;
        }
    }
    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#339fd4;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
            )
            right center / 100% auto no-repeat;
    }
}
