.is--home {
    .rwd002-container {
        margin-top: 0;
    }
}
@keyframes wave {
    0% {
        background-position: 1188px 0;
    }

    100% {
        background-position: 0 0;
    }
}
@keyframes wave_back {
    0% {
        background-position: 1397px 0;
    }

    100% {
        background-position: 0 0;
    }
}
@keyframes wave_sp {
    0% {
        background-position: 374px 0;
    }

    100% {
        background-position: 0 0;
    }
}

@keyframes scroll_line {
    from {
        transform: translateY(-25px);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes scroll_line_sp {
    from {
        transform: translateY(-15px);
    }
    to {
        transform: translateY(0);
    }
}

.mainvisual {
    position: relative;
    min-height: 598px;
    margin-top: 70px;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 2);
    padding-bottom: 220px;
    @include media-down(lg) {
        margin-top: 20px;
    }
    @include media-down(md) {
        padding-left: map-get($spacers, 2);
    }
    @include media-down(sm) {
        padding-bottom: 100px;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
        max-width: 1360px;
        margin-inline: auto;
        @include media-down(xl) {
            justify-content: space-between;
        }
        @include media-down(md) {
            display: block;
            width: 100%;
        }
    }
    ._parts {
        position: absolute;
        top: 0;
        left: 48px;
        z-index: 9;
        @include media-down(xl) {
            left: inherit;
            right: 0;
            width: 93%;
        }
        img {
            @include media-down(xl) {
                max-width: 100%;
                height: auto;
            }
        }
    }
    &__scroll {
        position: absolute;
        bottom: 190px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: calc(12 / 16 * $font-size-base);
        font-family: 'Ubuntu', $font-family-sans-serif;
        font-weight: 700;
        color: $secondary;
        text-align: center;
        letter-spacing: 0.08em;
        @include media-up(lg) {
            // bottom: 20px;
            // padding: 0 map-get($spacers, 3);
        }
        @include media-down(md) {
            position: relative;
            bottom: 0;
            margin-bottom: 10px;
            font-size: calc(11 / 16 * $font-size-base);
        }
        .bar {
            position: relative;
            display: block;
            margin-top: 3px;
            width: 11px;
            height: 58px;
            overflow: hidden;
            margin: auto;
            @include media-down(md) {
                height: 35px;
            }
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 11px;
                height: 58px;
                margin: auto;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.9 70.28"><defs><style>.cls-1{opacity:.5;}.cls-1,.cls-2{fill:#006ab3;stroke-width:0px;}.cls-3{fill:none;stroke:#006ab3;stroke-miterlimit:10;}</style></defs><g id="_デザイン"><circle class="cls-1" cx="5.45" cy="64.83" r="5.45"/><circle class="cls-2" cx="5.45" cy="64.83" r="2.6"/><line class="cls-3" x1="5.26" y1="0" x2="5.45" y2="64.78"/></g></svg>'
                    )
                    bottom center / 100% auto no-repeat;
                transform: translateY(-25px);
                animation: scroll_line 1200ms ease-in-out infinite alternate;
                @include media-down(md) {
                    height: 35px;
                    transform: translateY(-15px);
                    animation: scroll_line_sp 1200ms ease-in-out infinite
                        alternate;
                }
            }
        }
    }
    &__bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 168px;
        z-index: 1;
        @include media-down(sm) {
            height: 50px;
        }
        .wave1,
        .wave2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background-position: 0 0;
            background-repeat: repeat-x;
            @include media-down(sm) {
                animation: wave_sp linear infinite !important;
                background-size: 374px auto !important;
            }
        }
        .wave1 {
            z-index: 2;
            animation: wave linear infinite;
            animation-duration: 12s !important;
            background-size: 1188px auto;
            background-image: url(/assets/img/webp/home/program_bg_1_2x.webp);
            @include media-down(sm) {
                animation-duration: 8s !important;
                background-image: url(/assets/img/webp/home/program_bg_1_sp_2x.webp);
            }
        }
        .wave2 {
            animation: wave_back linear infinite;
            animation-duration: 30s !important;
            background-size: 1397px auto;
            background-image: url(/assets/img/webp/home/program_bg_2_2x.webp);
            @include media-down(sm) {
                animation-duration: 24s !important;
                background-image: url(/assets/img/webp/home/program_bg_2_sp_2x.webp);
            }
        }
    }
}
.mainvisual-slider {
    &__wrapper {
        position: relative;
        flex-shrink: 0;
        width: 667px;
        margin-left: 30px;
        @include media-down(xl) {
            margin-left: 0;
            width: 53%;
        }
        @include media-down(md) {
            width: 80%;
            margin: auto;
            padding-left: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
        @include media-down(sm) {
            width: 100%;
        }
    }
    .slide1 {
        padding-top: 7px;
        img {
            @include media-down(xl) {
                width: 95%;
            }
        }
    }
    .slide2 {
        padding-top: 20px;
        padding-left: 25px;
        @include media-down(xl) {
            padding-left: 15px;
        }
        @include media-down(md) {
            padding-top: 15px;
        }
    }
    .slide3 {
        padding-top: 35px;
        padding-left: 5px;
        @include media-down(md) {
            padding-top: 20px;
        }
        img {
            @include media-down(xl) {
                width: 97%;
            }
            @include media-down(md) {
                width: 100%;
            }
        }
    }
    .slide4 {
        padding-top: 35px;
        padding-left: 65px;
        @include media-down(md) {
            padding-top: 5px;
            padding-left: 20px;
        }
        img {
            @include media-down(xl) {
                width: 97%;
            }
            @include media-down(md) {
                width: 90%;
            }
        }
    }
    &__item {
        img {
            @include media-down(xl) {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
.mainvisual__content {
    width: 538px;
    // max-width: 38%;
    margin-left: 70px;
    @include media-down(xl) {
        width: 45%;
        margin-left: 0;
    }
    @include media-down(lg) {
    }
    @include media-down(md) {
        width: 100%;
    }
}
.mainvisual-catch {
    margin-top: 85px;
    @include media-down(md) {
        margin-bottom: 25px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    &__wrap {
        @include media-down(md) {
            padding-left: map-get($spacers, 2);
            padding-right: map-get($spacers, 2);
        }
    }
}
.block-headline {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 33px;
    background-color: #f0f0f0;
    border-radius: 50px;
    @include media-down(md) {
        border-radius: 5px;
    }
    @include media-down(md) {
        margin-top: 0;
        height: 65px;
    }
    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-shrink: 0;
        // width: 109px;
        width: 95px;
        height: 48px;
        margin-bottom: 0;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.12em;
        background-color: $secondary;
        @include media-down(lg) {
            width: 83px;
            font-size: calc(13 / 16 * $font-size-base);
        }
        @include media-down(md) {
            height: 65px;
            font-size: calc(13 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            // width: 19%;
        }
    }
    .date {
        margin-right: 0.5em;
        font-size: calc(12 / 16 * $font-size-base);
        font-family: 'Ubuntu', $font-family-sans-serif;
        // font-weight: 500;
        font-weight: 700;
        letter-spacing: 0.12em;
        color: #9f9e9e;
        @include media-down(md) {
            font-size: calc(11 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            font-size: calc(11 / 16 * $font-size-base);
        }
    }
    .line {
        @include media-down(md) {
            display: none;
        }
    }

    .cate {
        margin-left: 0.5em;
        margin-right: 12px;
        font-size: calc(12 / 16 * $font-size-base);
        font-weight: $font-weight-bold;
        color: $secondary;
        letter-spacing: 0.08em;
        // max-width: 12em;
        // text-overflow: ellipsis;
        // overflow: hidden;
        @include media-down(md) {
            color: #7899bd;
            letter-spacing: 0.14em;
        }
        @include media-down(sm) {
            font-size: calc(11 / 16 * $font-size-base);
        }
    }

    .subject {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.12em;
        margin: 0;
        color: #231815;
        transition: $transition-base;
        @include media-down(lg) {
            grid-column: 1 / span 2;
            font-size: calc(14 / 16 * $font-size-base);
        }
        @include media-down(md) {
            width: 100%;
            margin-top: 4px;
            letter-spacing: 0.14em;
        }
        &.is-new:after {
            display: inline-block;
            content: 'NEW';
            color: $secondary;
            font-family: 'Roboto', $font-family-sans-serif;
            font-weight: 500;
            margin-left: 0.2em;
            font-size: 0.8em;
        }
    }

    .icon {
        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            position: absolute;
            right: 11px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            margin: auto;
            color: #000;
        }
    }

    &__article {
        display: flex;
        align-items: center;
        width: 100%;
        @include media-down(lg) {
            position: relative;
            width: 100%;
        }
        @include media-down(md) {
            height: 100%;
        }
    }

    &__link {
        width: calc(100% - 109px);
        padding: 12px 15px;
        color: #666666;
        display: flex;
        align-items: center;
        white-space: nowrap;
        @include media-up(md) {
        }
        @include media-down(md) {
            flex-wrap: wrap;
            width: calc(100% - 83px);
            padding: 0 35px 0 15px;
            padding-right: calc(map-get($spacers, 3));
        }
        @include media-down(sm) {
        }
        &:hover,
        &:focus {
            text-decoration: none;
            .subject {
                text-decoration: underline;
                text-underline-offset: 0.2em;
                color: $primary;
            }
        }
    }
}
.block-program {
    position: relative;
    z-index: 1;
    margin-top: -60px;
    padding: 15px map-get($spacers, 3) 230px;
    background-color: $primary;
    @include media-down(md) {
        padding-bottom: 80px;
        padding-left: map-get($spacers, 2);
        padding-right: map-get($spacers, 2);
    }
    @include media-down(sm) {
        margin-top: -1px;
        padding-top: 35px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 500px;
        background-image: url(/assets/img/webp/home/pho-program-wave-bottom@2x.webp);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: bottom center;
        @include media-down(md) {
            height: 200px;
            background-image: url(/assets/img/webp/home/pho-program-wave-bottom_sp@2x.webp);
        }
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 1065px;
        margin-inline: auto;
    }
    [class*='illust'] {
        position: absolute;
    }
    .illust1 {
        top: 0;
        left: 43px;
        @include media-down(md) {
            left: 0;
            width: 114px;
            height: auto;
        }
        @include media-down(sm) {
            top: -40px;
        }
    }
    .illust2 {
        bottom: -50px;
        right: 0;
        @include media-down(md) {
            bottom: -105px;
            right: 12px;
            width: 98px;
            height: auto;
        }
    }
    &__title {
        @include c-title-primary();
        margin-bottom: 35px;
        @include media-down(md) {
            margin-bottom: 18px;
        }
        strong {
            color: #fff;
        }
        small {
            color: #0a59a0;
        }
        .icon {
            fill: #fff;
            margin-bottom: 15px;
        }
    }
    &__text {
        margin-bottom: 50px;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #fff;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            margin-bottom: 30px;
            padding-left: map-get($spacers, 1);
            padding-right: map-get($spacers, 1);
        }
        @include media-down(sm) {
            text-align: left;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: center;
    }
    &__btn {
        @include c-round-button();
    }
}
.block-program__list {
    margin: 50px 0 45px;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px 25px;
    @include media-down(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-down(md) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: inherit;
        margin: 30px 0 15px;
    }
}
.block-program__item {
    // @include media-up(md) {
    //     &:nth-child(n + 9) {
    //         display: none;
    //     }
    // }
    @include media-down(md) {
        width: calc((100% - 15px) / 2);
        margin-bottom: 15px;
    }
    a {
        transition: $transition-base;
        text-decoration: none !important;
        &:hover {
            .subject {
                color: #0a59a0;
                &::before {
                    background-color: #0a59a0;
                }
            }
            .block-program__item-img {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &-img {
        overflow: hidden;
        margin-bottom: 15px;
        aspect-ratio: 248 / 143;
        border-radius: 40px;
        @include media-down(md) {
            aspect-ratio: 161 / 95;
            border-radius: 5px;
            margin-bottom: 5px;
        }
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .subject {
        position: relative;
        transition: $transition-base;
        width: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline: auto;
        font-size: calc(18 / 16 * $font-size-base);
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.12em;
        line-height: calc(25 / 18);
        @include media-down(md) {
            width: fit-content;
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            letter-spacing: 0.1em;
        }
        @media (max-width: 380px) {
            font-size: calc(14 / 16 * $font-size-base);

        }
        &::before {
            content: '';
            display: inline-block;
            transition: $transition-base;
            width: 9px;
            height: 9px;
            margin-right: 8px;
            background-color: #fff;
            border-radius: 50%;
            @include media-down(md) {
                width: 6px;
                height: 6px;
                margin-right: 6px;
            }
        }
    }
}
.block-guide {
    overflow: hidden;
    padding: 80px 0 0;
    @include media-down(md) {
        padding: 60px 0 0;
    }
    &__title {
        @include c-title-primary();
        margin-bottom: 35px;
        @include media-down(md) {
            margin-bottom: 20px;
        }
    }
    &__container {
        position: relative;
        padding-left: map-get($spacers, 3);
        padding-right: map-get($spacers, 3);
        @include media-down(md) {
        }
        &__body {
            position: relative;
            width: 100%;
            max-width: 480px;
            margin-inline: auto;
            padding-bottom: 50px;
            @include media-down(lg) {
                padding-top: 0;
                padding-bottom: 35px;
            }
        }
        &__bg01 {
            position: absolute;
            top: -40px;
            right: 100%;
            z-index: -1;
            @media (max-width: 1140px) {
                right: 90%;
            }
            @include media-down(xl) {
                // right: 95%;
            }
            @include media-down(lg) {
                position: static;
                display: flex;
                justify-content: center;
                margin-bottom: 8px;
            }
            img {
                // max-width: 25vw;
                height: auto;
                @media (max-width: 1140px) {
                    max-width: 27vw;
                }
                @include media-down(lg) {
                    max-width: inherit;
                }
                @include media-down(sm) {
                    max-width: 90vw;
                }
            }
        }
        &__bg02 {
            position: absolute;
            z-index: -1;
            top: 25px;
            left: 100%;
            margin-bottom: 0;
            &.pc-block {
                @include media-up(lg) {
                    display: block;
                }
                @include media-down(lg) {
                    display: none;
                }
            }
            &.sp-block {
                @include media-up(lg) {
                    display: none;
                }
                @include media-down(lg) {
                    display: flex;
                }
            }
            @media (max-width: 1140px) {
                left: 93%;
            }
            @include media-down(xl) {
                // left: 91%;
            }
            @include media-down(lg) {
                position: static;
                justify-content: center;
                margin-top: 25px;
            }
            img {
                // max-width: 22vw;
                height: auto;
                @media (max-width: 1140px) {
                    max-width: 26vw;
                }
                @include media-down(lg) {
                    max-width: inherit;
                }
            }
        }
    }
    &__lead {
        margin-bottom: 25px;
        font-size: calc(26 / 16 * $font-size-base);
        font-weight: bold;
        color: $secondary;
        letter-spacing: 0.12em;
        line-height: calc(47 / 26);
        text-align: center;
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(18 / 16 * $font-size-base);
        }
    }
    &__text {
        margin: 0;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            text-align: left;
            br {
                display: none;
            }
        }
    }
}
.block-guide__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    max-width: 950px;
    margin-inline: auto;
    @include media-down(lg) {
        display: block;
    }
    @include media-up(sm) {
    }
}
.block-guide__item {
    &:not(:last-of-type) {
        @include media-up(lg) {
            background: url(/assets/img/webp/line-tate-blue@2x.webp) 100% 0 /
                auto 100% repeat-y;
        }
    }
    &:not(:first-of-type) {
        @include media-down(lg) {
            background: url(/assets/img/webp/line-yoko-blue@2x.webp) bottom
                left/auto 1px repeat-x;
        }
    }
    &:first-of-type {
        @include media-down(lg) {
            background-image: url(/assets/img/webp/line-yoko-blue@2x.webp),
                url(/assets/img/webp/line-yoko-blue@2x.webp);
            background-repeat: repeat-x;
            background-size: auto 1px;
            background-position: top left, bottom left;
        }
    }
    a {
        display: flex;
        align-items: center;
        padding: 20px 10px;
        text-decoration: none;
        @include media-up(lg) {
            flex-direction: column;
            justify-content: flex-end;
            min-height: 175px;
        }
        @include media-down(lg) {
            min-height: 84px;
            padding-right: map-get($spacers, 2);
            padding-left: map-get($spacers, 3);
        }
    }
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-up(lg) {
            width: 82px;
            height: 58px;
            margin-bottom: 20px;
        }
        @include media-down(lg) {
            flex-shrink: 0;
            width: 54px;
            height: 54px;
            margin-right: 15px;
        }
        img {
            max-width: 100%;
            height: auto;
            @include media-down(lg) {
                width: 100%;
            }
        }
    }
    &__title {
        transition: $transition-base;
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(16 / 16 * $font-size-base);
        text-align: center;
        text-decoration: none;
        letter-spacing: 0.12em;
        color: $text-color;
        @include media-up(lg) {
            transform: translateX(5px);
            width: max-content;
            min-height: 39px;
            padding-right: 21px;
        }
        @include media-down(lg) {
            width: 100%;
        }
        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 18px;
            height: 18px;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#339fd4;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
                )
                right center / 100% auto no-repeat;
        }
    }
    @media (hover: hover) {
        &:hover &__title {
            color: $primary;
        }
    }
}
.block-guide__bnr {
    margin-top: 55px;
    margin-bottom: 140px;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    @include media-down(lg) {
        margin-top: 25px;
    }
    @include media-down(md) {
        margin-bottom: 60px;
    }
    a {
        transition: $transition-base;
        display: block;
        width: 100%;
        max-width: 650px;
        margin-inline: auto;
        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
            }
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
    .rwd002-block-freearea {
        margin: 0;
        padding: 0;
    }
}
.block-facility {
    @include media-up(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        height: 473px;
    }
    &__img {
        @include media-up(lg) {
            height: 473px;
        }
        img {
            width: 100%;
            height: 100%;
            @include media-up(lg) {
                object-fit: cover;
                object-position: center;
            }
        }
    }
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: map-get($spacers, 2) map-get($spacers, 3);
        background-color: $primary;
        @include media-down(lg) {
            padding: 32px map-get($spacers, 3) 45px;
        }
        .fig {
            position: absolute;
            top: -77px;
            left: calc((100% / 2) + 155px);
            @media (max-width: 1140px) {
                left: calc((100% / 2) + 110px);
            }
            @include media-down(lg) {
                left: calc((100% / 2) + 155px);
            }
            @include media-down(sm) {
                top: -25px;
                left: inherit;
                right: 15px;
                width: 69px;
                height: auto;
            }
        }
    }
    &__title {
        @include c-title-primary();
        margin-bottom: 25px;
        @include media-down(md) {
            margin-bottom: 17px;
        }
        strong {
            color: #fff;
        }
        small {
            color: #0a59a0;
        }
        .icon {
            fill: #fff;
            margin-bottom: 10px;
        }
    }
    &__text {
        margin-bottom: 25px;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: #fff;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(sm) {
            text-align: left;
        }
    }
    &__list {
        @include e-list-base();
        display: grid;
        grid-template-columns: 44% 56%;
        row-gap: 15px;
        width: 345px;
        margin-inline: auto;
        @include media-down(sm) {
            grid-template-columns: 40% 60%;
            width: 100%;
        }
        li {
            color: #fff;
        }
    }
}
.block-news {
    padding: 100px map-get($spacers, 3) 60px;
    @include media-down(md) {
        padding: 55px map-get($spacers, 3) 40px;
    }
    &__title {
        @include c-title-primary();
        margin-bottom: 55px;
        @include media-down(md) {
            margin-bottom: 32px;
        }
    }

    &__inner {
        max-width: 100%;
        width: 960px;
        margin: 0 auto;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        gap: 0 60px;
    }

    &__body {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    &__btnwrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
        margin-bottom: 50px;
        @include media-down(md) {
        }
        .btn {
            padding-right: 0;
            font-size: calc(12 / 16 * $font-size-base);
            font-family: 'Ubuntu', $font-family-sans-serif;
            font-weight: 700;
            text-decoration: none;
            color: $secondary;
            letter-spacing: 0.12em;
            @include media-down(md) {
            }
            span {
                margin-right: 7px;
                padding: 0.375rem 0.75rem;
                border-bottom: 1px solid $primary;
            }
            .icon {
                color: $primary;
            }
            &:hover,
            &:focus {
                color: $primary;
            }
        }
    }

    &__bnr {
        transition: $transition-base;
        width: 100%;
        max-width: 653px;
        margin-inline: auto;
        img {
            width: 100%;
            height: auto;
        }
        @media (hover: hover) {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}
.block-news-item {
    display: flex;
    width: 100%;
    padding: 20px 0;
    margin-top: -1px;
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: auto 1px;
    background-image: url(/assets/img/webp/line-yoko-blue@2x.webp);
    @include media-up(lg) {
        width: calc(50% - 30px);
    }
    @include media-down(lg) {
        align-items: center;
        padding: 10px 0;
    }

    &:nth-child(-n + 2) {
        background-image: url(/assets/img/webp/line-yoko-blue@2x.webp),
            url(/assets/img/webp/line-yoko-blue@2x.webp);
        background-position: left top, left bottom;
        @include media-down(lg) {
            background-image: url(/assets/img/webp/line-yoko-blue@2x.webp);
            background-position: left bottom;
        }
    }
    &:first-of-type {
        @include media-down(lg) {
            background-image: url(/assets/img/webp/line-yoko-blue@2x.webp),
                url(/assets/img/webp/line-yoko-blue@2x.webp);
            background-position: left top, left bottom;
        }
    }

    &:only-child {
        margin-left: auto;
        margin-right: auto;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        .title {
            opacity: 0.7;
        }
        img {
            transform: scale(1.1);
        }
    }

    &__thumb {
        flex-shrink: 0;
        aspect-ratio: 1/1;
        width: 108px;
        margin: 0;
        overflow: hidden;
        z-index: 0;
        @include media-up(lg) {
            width: 118px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: $transition-base;
        }
    }

    &__body {
        padding: 0 0 0 30px;
        @include media-down(lg) {
            flex-grow: 1;
            padding: 0 0 0 24px;
        }
    }

    .date-cate {
        display: inline-flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 3px;
        color: $text-color;
        font-size: calc(12 / 16 * $font-size-base);
        line-height: 2;
        .date,
        .cate {
            display: inline-block;
        }
        .date {
            margin-right: 0.5em;
            font-family: 'Ubuntu', $font-family-sans-serif;
            font-weight: 500;
            color: #666666;
            letter-spacing: 0.15em;
        }

        .category {
            @extend .text-truncate;
            margin-left: 0.5em;
            font-weight: bold;
            color: $secondary;
            letter-spacing: 0.08em;
        }
    }

    .title {
        display: block;
        margin-top: 3px;
        font-weight: $font-weight-bold;
        font-size: calc(16 / 16 * $font-size-base);
        line-height: calc(28 / 16);
        letter-spacing: 0.12em;
        transition: $transition-base;
        @include media-up(lg) {
            margin-top: 10px;
            letter-spacing: 0.1em;
        }
        @include media-down(md) {
            font-size: calc(13 / 16 * $font-size-base);
        }
        &.is-new:after {
            @extend .text-truncate;
            content: 'NEW';
            display: inline-block;
            vertical-align: -0.4em;
            padding: 0 0.75em;
            font-family: 'Ubuntu', $font-family-sans-serif;
            font-weight: 500;
            color: $primary;
            letter-spacing: 0.14em;
        }
    }
}
