.rwd002-gnav {
    @include media-up(lg) {
        margin-left: map-get($spacers, 3);
    }

    @include media-down(lg) {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        z-index: 100;
        background-color: transparent;
        transform: translateX(100%);
        transition: background-color 0.2s ease-in-out, transform 0s 0.5s;
    }

    &.is-active {
        // メニューが開いたときのオーバーレイ
        @include media-down(lg) {
            background-color: fade-out($black, 0.2);
            transform: translateX(0%);
            transition: background-color 0.2s ease-in-out, transform 0s 0s;
        }
    }

    &__inner {
        @include media-up(lg) {
            display: flex;
        }
        // 格納メニュー
        @include media-down(lg) {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            padding: $gnav-height-md map-get($spacers, 2) map-get($spacers, 2);
            width: calc(100vw - map-get($spacers, 5));
            transform: translateX(100%);
            transition: $transition-base;
            background-color: $white;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        @include media-down(md) {
            padding-top: $gnav-height-xs;
        }
    }

    .is-active &__inner {
        @include media-down(lg) {
            transform: translateX(0%);
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: stretch;
        flex-direction: column;

        @include media-up(lg) {
            // margin-right: -0.75em;
            margin-right: 15px;
            padding-top: 27px;
            align-items: stretch;
            flex-direction: row;
        }
        @include media-down(xl) {
            margin-right: 0;
        }
    }

    &__copyright {
        @include media-up(lg) {
            display: none;
        }
        @include media-down(lg) {
            font-size: $font-size-xs;
            color: tint($body-color, 50%);
        }
    }
}

.rwd002-gnav__contact {
    a {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 225px;
        height: 57px;
        text-decoration: none;
        color: #fff;
        @include media-down(xl) {
            width: 190px;
        }
        @media (max-width: 1175px) {
            width: 185px;
        }
        @include media-down(lg) {
            width: 100%;
            height: 46px;
            margin-top: 8px;
            border-radius: 6px !important;
        }
        span {
            display: flex;
            align-items: center;
            min-height: 23px;
            padding-left: 38px;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 23px auto;
            @include media-down(xl) {
                padding-left: 30px;
            }
        }
    }
    .inquiry {
        font-size: calc(13 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.05em;
        background-color: $secondary;
        @include media-down(lg) {
            font-size: calc(14 / 16 * $font-size-base);
        }
        span {
            background-image: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><defs><style>.cls-1{clip-path:url(#clippath);}.cls-2{fill:none;}.cls-2,.cls-3,.cls-4{stroke-width:0px;}.cls-3{fill:#fff;}.cls-4{fill:#006ab3;}</style><clipPath id="clippath"><rect class="cls-2" x="5.48" y="7.59" width="12.77" height="8.96"/></clipPath></defs><g id="_デザイン"><circle class="cls-3" cx="12" cy="12" r="12"/><g class="cls-1"><path class="cls-4" d="m5.86,7.78c-.16,0-.3.13-.3.3v1.26l6.36,4.42,6.33-4.36v-1.32c0-.16-.13-.3-.3-.3H5.86Zm-.3,3.01v5.35c0,.16.13.3.3.3h12.09c.16,0,.3-.13.3-.3v-5.3l-5.99,4.13c-.2.14-.47.14-.68,0l-6.02-4.18Z"/></g></g></svg>'
            );
        }
        &:hover,
        &:focus {
            background-color: #003876;
            span {
                background-image: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.94 22.94"><defs><style>.cls-1{clip-path:url(#clippath);}.cls-2{fill:none;}.cls-2,.cls-3,.cls-4{stroke-width:0px;}.cls-3{fill:#fff;}.cls-4{fill:#003876;}</style><clipPath id="clippath"><rect class="cls-2" x="5.37" y="7.25" width="12.21" height="8.56"/></clipPath></defs><g id="_デザイン"><circle class="cls-3" cx="11.47" cy="11.47" r="11.47"/><g class="cls-1"><path class="cls-4" d="m5.73,7.44c-.16,0-.28.13-.28.28v1.21l6.08,4.22,6.05-4.17v-1.26c0-.16-.13-.28-.28-.28H5.73Zm-.28,2.87v5.11c0,.16.13.28.28.28h11.56c.16,0,.28-.13.28-.28v-5.06l-5.73,3.95c-.19.13-.45.13-.65,0l-5.75-4Z"/></g></g></svg>'
                );
            }
        }
    }
    .tel {
        font-size: calc(17 / 16 * $font-size-base);
        font-family: 'Ubuntu', $font-family-sans-serif;
        font-weight: 700;
        letter-spacing: 0.13em;
        background-color: #ff7000;
        border-radius: 0 0 0 10px;
        span {
            background-image: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.94 22.94"><defs><style>.cls-1{fill:#ff7000;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#fff;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="11.47" cy="11.47" r="11.47"/><path class="cls-1" d="m7.24,5.33c-.89.58-1.07,2.39-.73,3.87.26,1.15.82,2.69,1.76,4.14.89,1.37,2.12,2.59,3.06,3.3,1.21.91,2.93,1.49,3.83.91.45-.29,1.12-1.09,1.15-1.24,0,0-.39-.61-.48-.74l-1.32-2.03c-.1-.15-.71-.01-1.03.16-.42.24-.81.88-.81.88-.29.17-.53,0-1.04-.23-.62-.29-1.32-1.17-1.88-1.98-.51-.84-1.03-1.84-1.04-2.52,0-.56-.06-.85.21-1.04,0,0,.74-.09,1.13-.38.29-.21.67-.72.57-.87l-1.32-2.03c-.09-.13-.48-.74-.48-.74-.14-.03-1.15.26-1.6.55Z"/></g></svg>'
            );
        }
        &:hover,
        &:focus {
            background-color: #ff4e00;
            span {
                background-image: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.94 22.94"><defs><style>.cls-1{fill:#fff;}.cls-1,.cls-2{stroke-width:0px;}.cls-2{fill:#ff4e00;}</style></defs><g id="_デザイン"><circle class="cls-1" cx="11.47" cy="11.47" r="11.47"/><path class="cls-2" d="m7.24,5.33c-.89.58-1.07,2.39-.73,3.87.26,1.15.82,2.69,1.76,4.14.89,1.37,2.12,2.59,3.06,3.3,1.21.91,2.93,1.49,3.83.91.45-.29,1.12-1.09,1.15-1.24,0,0-.39-.61-.48-.74l-1.32-2.03c-.1-.15-.71-.01-1.03.16-.42.24-.81.88-.81.88-.29.17-.53,0-1.04-.23-.62-.29-1.32-1.17-1.88-1.98-.51-.84-1.03-1.84-1.04-2.52,0-.56-.06-.85.21-1.04,0,0,.74-.09,1.13-.38.29-.21.67-.72.57-.87l-1.32-2.03c-.09-.13-.48-.74-.48-.74-.14-.03-1.15.26-1.6.55Z"/></g></svg>'
                );
            }
        }
    }
}

.rwd002-gnav-sublist {
    // sitemap.phpなどのシステムメニュー
    list-style: none;
    padding: 0;
    @include media-up(lg) {
        display: none;
    }
    @include media-down(lg) {
        display: flex;
        flex-wrap: wrap;
        margin: map-get($spacers, 2) 0;
        color: tint($body-color, 50%);
        font-size: $font-size-xs;
        margin-left: -0.5em;
        margin-right: -0.5em;
        &__item {
            position: relative;
            padding: 0.2em 0.6em;
        }
        &__item + &__item:before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
            content: '';
            height: 1em;
            width: 1px;
            background-color: tint($body-color, 50%);
        }
    }
}

// グローバルナビ　Item(親)
.rwd002-gnav-item {
    display: flex;
    flex-wrap: wrap;
    font-size: calc(14 / 16 * $font-size-base);
    letter-spacing: 0.14em;
    text-indent: 0.14em;
    font-feature-settings: 'palt';
    @include media-up(lg) {
        &:not(:last-of-type) {
            .rwd002-gnav-item__link {
                background: url(/assets/img/webp/line-tate-blue@2x.webp) 100% 0 /
                    1px auto repeat-y;
            }
        }
    }
    @include media-down(lg) {
        border: solid $gray-300;
        border-width: 1px 0;
        & + & {
            border-top-width: 0;
        }
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: $font-weight-bold;
        transition: $transition-base;
        padding: 0.25em 0 0.25em 1.5em;
        min-height: 3.3em;
        color: $text-color;

        @include media-up(lg) {
            justify-content: center;
            flex-direction: column;
            min-width: 138px;
            height: 83px;
            padding: 0 20px;
            min-height: 83px;
        }
        @include media-down(xl) {
            min-width: 130px;
            padding: 0 10px;
        }
        @media (max-width: 1175px) {
            min-width: 100px;
            padding: 0 8px;
        }
        @include media-down(lg) {
            flex-grow: 1;
            flex-basis: calc(100% - 50px);
            padding-left: 0;
        }
        .icon-wrap {
            @include media-down(lg) {
                display: flex;
                justify-content: center;
                width: 42px;
            }
        }
        .icon {
            transition: $transition-base;
            fill: $secondary;
            @include media-down(md) {
                transform: scale(0.9);
                transform-origin: center;
            }
        }

        &:hover,
        &:focus,
        &.current {
            color: $text-color;
            text-decoration: none;
            .icon {
                fill: $primary;
            }
        }

        // PC表示のメニュー装飾
        @include media-up(lg) {
            span {
                position: relative;
                padding-top: 0.5em;
                padding-bottom: 0.5em;

                // &:after {
                //     content: '';
                //     position: absolute;
                //     top: 100%;
                //     left: calc(50% - min(2em, 50%));
                //     height: 1px;
                //     width: 0;
                //     background-color: $primary;
                //     transition: $transition-base;
                //     opacity: 0;
                // }
            }
            &.current span:after {
                max-width: 100%;
                width: 4em;
                opacity: 1;
            }
        }

        // SP表示のメニュー装飾
        @include media-down(lg) {
            // &:after {
            //     position: absolute;
            //     content: '';
            //     top: calc(50% - 0.3em);
            //     left: 0;
            //     width: 1.5em;
            //     height: 0.6em;
            //     background: center / contain no-repeat;
            //     background-image: svgUrlFunc(
            //         '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$body-color}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
            //     );
            // }
        }
    }
}

// グローバルナビ subnav(子)

/* HTML構成例
<div class="rwd002-subnav">
  <div class="rwd002-subnav__inner">
    <ul class="rwd002-subnav__list">
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー01</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー02</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
      <li class="rwd002-subnav__item">
        <a href="" class="rwd002-subnav__link"><span>サブメニュー03</span></a>
      </li>
      <!-- /.rwd002-subnav__item -->
    </ul>
    <!-- /.rwd002-subnav__list -->
  </div>
  <!-- /.rwd002-subnav__inner -->
</div>
<!-- /.rwd002-subnav -->
*/

.rwd002-subnav {
    @include media-up(lg) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        transform-origin: 0 0;
        transform: scale(1, 0);
        transition: transform 0.2s 0.2s ease-in-out;

        &__inner {
            background-color: $primary;
            color: $white;
        }

        &__btn {
            display: none;
        }

        .rwd002-gnav-item:hover & {
            transform: scale(1, 1);
        }

        &.collapse:not(.show) {
            display: block;
        }
    }
    @include media-down(lg) {
        flex-basis: 100%;
    }

    &__inner {
        @include media-up(lg) {
            margin: 0 auto;
        }
    }

    &__list {
        list-style: none;
        padding: 0 1.5em;

        @include media-up(lg) {
            display: flex;
            align-items: stretch;
            justify-content: center;
        }

        @include media-down(lg) {
            padding-right: 0;
            padding-bottom: 1em;
        }
    }

    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(lg) {
            display: none;
        }
        @include media-down(lg) {
            position: relative;
            flex-basis: 50px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 1px;
                background-color: $primary;
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
}
.rwd002-header .rwd002-subnav-item {
    &__link {
        display: block;
        transition: $transition-base;
        &:hover,
        &:focus {
            text-decoration: none;
            opacity: 0.8;
        }
        @include media-up(lg) {
            padding: 0.75em 1em;
        }
        @include media-down(lg) {
            padding: 0.2em 0;
        }
    }
}
