@keyframes up_down_line {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.rwd002-footerSocial {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    @include media-down(lg) {
        flex-direction: inherit;
        justify-content: center;
        margin-left: 0;
    }
    @include media-down(sm) {
        justify-content: space-between;
    }
    &__title {
        letter-spacing: 0.16em;
        margin-right: 1em;
        font-family: 'Lato', $font-family-sans-serif;
    }
    &__btn {
        transition: $transition-base;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 161px;
        height: 41px;
        font-size: calc(12 / 16 * $font-size-base);
        font-family: 'Ubuntu', $font-family-sans-serif;
        font-weight: 500;
        text-decoration: none !important;
        color: #373738 !important;
        letter-spacing: 0.12em;
        border: solid 1px #373738;
        border-radius: 100px;
        @include media-down(lg) {
            margin: auto;
        }
        &:not(:last-child) {
            margin-bottom: 7px;
            @include media-down(lg) {
                margin-bottom: 0;
            }
        }
        span {
            display: inline-block;
            font-size: calc(14 / 16 * $font-size-base);
            margin-left: 5px;
        }
        i {
            font-size: calc(17 / 16 * $font-size-base);
        }
        @media (hover: hover) {
            &:hover {
                background-color: rgba(51, 159, 212, 0.2);
            }
        }
    }
}

.rwd002-pagetop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -50px;
    width: 68px;
    height: 68px;
    font-size: calc(12 / 16 * $font-size-base);
    font-family: 'Ubuntu', $font-family-sans-serif;
    font-weight: 500;
    color: $primary !important;
    text-decoration: none;
    letter-spacing: 0.08em;
    transform-origin: bottom;
    border: 1px solid $primary;
    border-radius: 50%;
    background-color: $white;

    @include media-up(lg) {
        right: map-get($spacers, 5);
    }
    @include media-down(lg) {
        width: 58px;
        height: 58px;
        right: calc(50% - 29px);
        border-width: 2px;
        font-size: calc(10 / 16 * $font-size-base);
        font-weight: 700;
    }
    @include media-down(md) {
        bottom: -60px;
    }
    &:before {
        content: '';
        display: block;
        margin-bottom: 5px;
        width: 19px;
        height: 7px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 7.63"><defs><style>.cls-1{fill:none;stroke:#339fd4;stroke-miterlimit:10;}</style></defs><g id="_デザイン"><polyline class="cls-1" points="18.91 7.22 9.6 .61 .29 7.22"/></g></svg>'
            )
            no-repeat center / contain;
        @include media-down(lg) {
            margin-bottom: 2px;
            width: 16px;
            height: 10px;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.93 10.57"><defs><style>.cls-1{fill:none;stroke:#339fd4;stroke-miterlimit:10;stroke-width:2px;}</style></defs><g id="_デザイン"><path class="cls-1" d="m.74,9.9L8.47,1.48l7.73,8.42"/></g></svg>'
                )
                no-repeat center / contain;
        }
    }
    &:hover,
    &:focus {
        text-decoration: none;
        &:before {
            animation: up_down_line 0.9s infinite ease-in-out;
        }
    }
    &__wrap {
        position: relative;
        width: 100%;
        z-index: 10;
        transition: $transition-base;
        @include media-down(lg) {
            position: relative;
        }
    }
}

.rwd002-footer {
    position: relative;
    margin-top: -110px;
    color: $black;
    @include media-up(md) {
        padding: map-get($spacers, 5) map-get($spacers, 5) map-get($spacers, 3);
    }
    @include media-down(lg) {
        padding: 60px map-get($spacers, 5) 25px;
    }
    @include media-down(md) {
        margin-top: 0;
        padding: 45px map-get($spacers, 3) 25px;
    }

    &__inner {
        width: 100%;
        max-width: 860px;
        margin: 0 auto;
        @include media-up(lg) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-down(lg) {
        }
    }

    &__info {
        background: url(/assets/img/webp/footer-line@2x.webp) no-repeat right
            top / auto 100%;
        @include media-up(lg) {
            height: 237px;
        }
        @include media-down(lg) {
            width: 100%;
            margin-bottom: 30px;
            padding-bottom: 38px;
            background: url(/assets/img/webp/line-yoko-blue@2x.webp) bottom
                center / auto 1px repeat-x;
        }
    }
    &__companyLogo {
        @include media-up(lg) {
            margin-bottom: 20px;
        }
        @include media-down(lg) {
            display: flex;
            justify-content: center;
            margin-bottom: 13px;
            img {
                transform: translateX(-7px);
            }
        }
    }
    &__companyBody {
        @include media-up(lg) {
            padding-left: 8px;
        }
        @include media-down(lg) {
            text-align: center;
        }
        .btn-link {
            margin-top: 2px;
            padding: 0;
            font-size: calc(12 / 16 * $font-size-base);
            font-weight: normal;
            letter-spacing: 0.12em;
            transition: $transition-base;
            &:hover,
            &:focus {
                text-decoration: none;
                opacity: 0.7;
            }
            .icon {
                margin-right: 1px;
                color: $primary;
            }
            span {
                font-family: $font-family-sans-serif;
                color: $black;
            }
        }
    }
    &__companyName {
        margin-bottom: 0;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
    }
    &__address {
        margin: 0;
        font-size: calc(14 / 16 * $font-size-base);
        letter-spacing: 0.12em;
        line-height: calc(25 / 14);
        &:last-child {
            margin-bottom: 0;
        }
        // .zip,
        // .addr2 {
        //     display: none;
        // }
        .tel,
        .fax,
        .opentime {
            display: block;
            line-height: 1;
            font-feature-settings: 'palt';
        }
    }
    &__tel {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 20px;
        font-size: calc(14 / 16 * $font-size-base);
        letter-spacing: 0.12em;
        @include media-down(lg) {
            justify-content: center;
            margin-bottom: 15px;
        }
        .tel {
            margin-right: 11px;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 55px;
        padding-bottom: 43px;
        @include media-down(lg) {
            padding-left: 0;
            padding-bottom: 0;
        }
    }

    &__bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 55px;
        @include media-down(lg) {
            margin-top: 30px;
        }
        @include media-down(md) {
            flex-direction: column;
        }
        .slash {
            margin: 0 15px;
            color: #b1b1b1;
            @include media-down(md) {
                display: none;
            }
        }
    }
    &__copyright,
    &__login {
        font-size: calc(11 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        color: #b1b1b1;
        // font-feature-settings: 'palt';
    }

    &__copyright {
        @include media-down(md) {
            margin-left: -1.3em;
            margin-right: -1.3em;
        }
    }

    &__login {
        @include media-down(md) {
            margin-top: 1em;
        }
    }
}

.rwd002-footerNav {
    @include media-up(lg) {
        // display: grid;
        // // grid-template: 1fr auto / 2fr 1fr;
        // flex-basis: 60%;
    }
    @include media-down(lg) {
        display: flex;
        flex-wrap: wrap;
    }
    @include media-down(md) {
        display: block;
    }
    &__list,
    &__sublist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        // grid-template-columns: repeat(2, 1fr);
        font-size: calc(14 / 16 * $font-size-base);
        // color: darken($white, 20%);
        @include media-up(md) {
            // column-count: 4;
            // column-gap: 1em;
        }
        @include media-up(lg) {
            grid-column: 1;
            grid-row: 1 / span 2;
            column-gap: 0;
        }
        @include media-down(md) {
        }
    }
    &__sublist {
        display: flex;
        margin-top: 6px;
        font-size: $font-size-xs;
        color: darken($white, 40%);
        .slash {
            display: flex;
            align-items: center;
            font-size: calc(14 / 16 * $font-size-base);
            color: #9b9b9b;
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
            }
        }
    }
}

.rwd002-footerNav-item {
    display: flex;
    flex-wrap: wrap;
    min-width: 185px;
    margin-bottom: 2px;
    @include media-up(md) {
        padding-right: 1em;
    }
    @include media-down(md) {
        width: 50%;
        min-width: inherit;
    }
    &__link {
        position: relative;
        display: inline-block;
        padding: 0 0.5em 0 1em;
        font-size: calc(14 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        font-weight: bold;
        color: $text-color;
        letter-spacing: 0.13em;
        line-height: calc(30 / 14);
        &:hover,
        &:focus {
            color: $text-color;
        }
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 0.3em;
            top: 1em;
            left: 0;
            background-color: $text-color;
        }
        @include media-down(md) {
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
}

.rwd002-footerNav-subitem {
    &__link {
        position: relative;
        display: inline-block;
        padding: 0.3em 0.5em 0.3em 1em;
        font-size: calc(12 / 16 * $font-size-base);
        letter-spacing: 0.13em;
        line-height: calc(16 / 12);
        color: #9b9b9b;
        transition: $transition-base;
        &:hover,
        &:focus {
            color: $text-color;
        }
        @include media-down(lg) {
            margin-bottom: 0;
        }
        @include media-down(md) {
            display: block;
            font-size: calc(11 / 16 * $font-size-base);
        }
    }
    &:first-of-type &__link {
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 0.3em;
            top: 1em;
            left: 0;
            background-color: #9b9b9b;
        }
    }
    &:not(:first-of-type) &__link {
        padding-left: 0.5em;
    }
}

.rwd002-footer-subnav {
    @include media-up(md) {
        display: none;
    }
    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            position: relative;
            flex-basis: 40px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(50% - 5px);
                width: 10px;
                height: 1px;
                background-color: darken($white, 50%);
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__list {
        list-style: none;
        @include media-down(md) {
            padding-left: 1.7em;
            padding-bottom: 1em;
        }
    }
}

.rwd002-footer-search {
    margin-top: 2em;
    @include media-down(lg) {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
    }
    &__inner {
        position: relative;
        max-width: 320px;
        @include media-down(md) {
            margin-left: -0.8em;
            margin-right: -0.8em;
        }
    }
    &__input {
        @extend .form-control;
        padding-right: 2.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: tint($dark, 10%);
        transition: $transition-base;
        border-width: 0;
    }
    &__btn {
        @extend .btn;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 2.2em);
        width: 2.2em;
        padding: 0 0.5em;
        pointer-events: none;
        transition: $transition-base;
        > svg {
            width: 1em;
            height: 100%;
            fill: tint($dark, 30%);
        }
    }
    &__btn:focus,
    &__input:focus + &__btn {
        pointer-events: auto;
        right: 0;
    }
}

.footer-head {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 474px;
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);
    background: url(/assets/img/webp/footer_bg@2x.webp)
        center / auto 430px no-repeat;
    @include media-down(md) {
        min-height: 379px;
        background: url(/assets/img/webp/footer_bg_sp@2x.webp) center / auto
            100% no-repeat;
        // background: svgUrlFunc(
        //         '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 375 351.2"><defs><style>.cls-1{clip-path:url(#clippath);}.cls-2{fill:none;}.cls-2,.cls-3,.cls-4{stroke-width:0px;}.cls-3{opacity:.2;}.cls-3,.cls-4{fill:#339fd4;}.cls-5{clip-path:url(#clippath-1);}</style><clipPath id="clippath"><rect class="cls-2" x="0" width="375" height="235"/></clipPath><clipPath id="clippath-1"><rect class="cls-2" x="0" y="116.2" width="375" height="235" transform="translate(375 467.4) rotate(-180)"/></clipPath></defs><g id="_背景"><g class="cls-1"><path class="cls-3" d="m380.97,2.86s-50.64,16.43-100.81,16.15C224.51,18.71,167.52.65,95.97,1.76,24.42,2.87-11.78,23.26-11.78,23.26l.38,211.13h393.11s.71-230.07-.75-231.53Z"/><path class="cls-4" d="m375.27,9.31s-48.48,9.66-97.5,9.38c-54.37-.31-110.63-13.73-180.54-12.62C27.32,7.18-7.5,24.11-7.5,24.11v213.47h384.11s-1.99-228.27-1.34-228.27Z"/></g><g class="cls-5"><path class="cls-3" d="m-5.97,348.33s50.64-16.43,100.81-16.15c55.65.31,112.64,18.37,184.19,17.26,71.55-1.11,107.75-21.5,107.75-21.5l-.38-211.13H-6.72s-.71,230.07.75,231.53Z"/><path class="cls-4" d="m-.27,341.88s48.48-9.66,97.5-9.38c54.37.31,110.62,13.73,180.54,12.62,69.91-1.11,104.73-18.04,104.73-18.04V113.62s-384.11,0-384.11,0c0,0,1.99,228.27,1.34,228.27Z"/></g></g></svg>'
        //     )
        //     center / auto 100% no-repeat;
    }
}
.footer-contact {
    display: flex;
    align-items: center;
    width: 960px;
    margin-bottom: 30px;
    margin-inline: auto;
    @include media-down(lg) {
        flex-direction: column;
    }
    @include media-down(md) {
        margin-bottom: 0;
    }
    &__title {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        min-height: 125px;
        margin: 0;
        font-size: calc(19 / 16 * $font-size-base);
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0.13em;
        line-height: calc(32 / 19);
        background: url(/assets/img/line-tate-white.svg) right center / 1px auto
            no-repeat;
        @media (max-width: 999px) {
            justify-content: flex-start;
            width: 160px;
        }
        @include media-down(lg) {
            justify-content: center;
            width: 100%;
            max-width: 768px;
            min-height: auto;
            margin-bottom: 13px;
            padding-bottom: 13px;
            background: url(/assets/img/webp/line-yoko-white@2x.webp) bottom
                center / auto 1px repeat-x;
        }
        @include media-down(md) {
            font-size: calc(16 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            max-width: 260px;
        }
    }

    &__body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 190px);
        padding-left: 34px;
        padding-right: 20px;
        @media (max-width: 999px) {
            width: calc(100% - 160px);
            padding-left: 20px;
            padding-right: 0;
        }
        @include media-down(lg) {
            width: 100%;
            max-width: 768px;
            padding: 0;
        }
        @include media-down(md) {
            flex-direction: column;
        }
    }

    &__tel {
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: calc(30 / 16 * $font-size-base);
        @include media-down(md) {
            width: max-content;
            align-items: center;
        }
        .tel {
            position: relative;
            display: block;
            padding-left: 40px;
            font-family: 'Ubuntu', $font-family-sans-serif;
            font-weight: 500;
            color: #fff;
            letter-spacing: 0.13em;
            @include media-down(lg) {
                padding-left: 35px;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0.23em;
                left: 0;
                display: block;
                width: 32px;
                height: 32px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#339fd4;}</style></defs><g id="_文字"><circle class="cls-1" cx="14" cy="14" r="14"/><path class="cls-2" d="m10.01,7.36l1.66,1.66c.24.24.22.65-.04.91l-.67.67c-.24.24-.28.6-.09.85,0,0,.23,1.54,2.03,3.34,1.84,1.84,3.29,1.97,3.29,1.97.25.19.61.15.85-.09l.67-.67c.26-.26.67-.28.91-.04l1.66,1.66c.24.24.22.65-.04.91,0,0-.1.1-1.09,1.09-1.15,1.15-5.37-1.05-7.72-3.41-2.36-2.36-4.56-6.57-3.41-7.72.99-.99,1.08-1.08,1.09-1.09.26-.26.67-.28.91-.04Z"/></g></svg>'
                    )
                    left center / 100% auto no-repeat;
                @include media-down(lg) {
                    top: 0.27em;
                    width: 27px;
                    height: 27px;
                }
            }
        }
        .opentime {
            font-size: calc(12 / 16 * $font-size-base);
            letter-spacing: 0.19em;
            font-feature-settings: 'palt';
        }
    }

    .opentime {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 4px;
        color: #fff;
        @media (max-width: 999px) {
            // display: block;
        }
        @include media-down(md) {
            display: block;
            margin-top: 7px;
            margin-bottom: 13px;
        }
        &__time {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 71px;
            height: 21px;
            margin: 0 13px 0 0;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 13px;
            font-size: calc(12 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.12em;
            border: solid 1px #fff;
            border-radius: 2px;
            @media (max-width: 999px) {
                margin-right: 10px;
                // margin-bottom: 6px;
                // margin-right: 0;
            }
            @include media-down(lg) {
                width: 57px;
                height: 17px;
                font-size: calc(10 / 16 * $font-size-base);
            }
            @include media-down(md) {
                margin: 0 auto 5px;
            }
        }
        &-wrap {
            display: flex;
            align-items: center;
        }
        &__text {
            margin-top: 0;
            margin-bottom: 0;
            font-size: calc(14 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.12em;
            @include media-down(md) {
                font-size: calc(13 / 16 * $font-size-base);
            }
            @include media-down(sm) {
                font-size: calc(11 / 16 * $font-size-base);
            }
            &:not(:last-of-type) {
                margin-right: 18px;
                @media (max-width: 999px) {
                    margin-right: 13px;
                }
            }
            .week {
                position: relative;
                margin-right: 8px;
                padding-right: 8px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 18px;
                    background-color: #fff;
                    @include media-down(md) {
                        top: 2px;
                        height: 12px;
                    }
                    @include media-down(sm) {
                        top: 1px;
                    }
                }
            }
        }
    }

    &__btn {
        transition: $transition-base;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 276px;
        height: 74px;
        font-size: calc(17 / 16 * $font-size-base);
        font-weight: bold;
        color: #fff !important;
        text-decoration: none !important;
        letter-spacing: 0.08em;
        background-color: $secondary;
        border-radius: 3px;
        @include media-down(lg) {
            width: 268px;
            height: 58px;
        }
        span {
            padding-left: 33px;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            background-image: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><defs><style>.cls-1{clip-path:url(#clippath);}.cls-2{fill:none;}.cls-2,.cls-3,.cls-4{stroke-width:0px;}.cls-3{fill:#fff;}.cls-4{fill:#006ab3;}</style><clipPath id="clippath"><rect class="cls-2" x="5.48" y="7.59" width="12.77" height="8.96"/></clipPath></defs><g id="_デザイン"><circle class="cls-3" cx="12" cy="12" r="12"/><g class="cls-1"><path class="cls-4" d="m5.86,7.78c-.16,0-.3.13-.3.3v1.26l6.36,4.42,6.33-4.36v-1.32c0-.16-.13-.3-.3-.3H5.86Zm-.3,3.01v5.35c0,.16.13.3.3.3h12.09c.16,0,.3-.13.3-.3v-5.3l-5.99,4.13c-.2.14-.47.14-.68,0l-6.02-4.18Z"/></g></g></svg>'
            );
        }
        &:hover,
        &:focus {
            background-color: #003876;
            border-color: #fff;
            span {
                background-image: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.94 22.94"><defs><style>.cls-1{clip-path:url(#clippath);}.cls-2{fill:none;}.cls-2,.cls-3,.cls-4{stroke-width:0px;}.cls-3{fill:#fff;}.cls-4{fill:#003876;}</style><clipPath id="clippath"><rect class="cls-2" x="5.37" y="7.25" width="12.21" height="8.56"/></clipPath></defs><g id="_デザイン"><circle class="cls-3" cx="11.47" cy="11.47" r="11.47"/><g class="cls-1"><path class="cls-4" d="m5.73,7.44c-.16,0-.28.13-.28.28v1.21l6.08,4.22,6.05-4.17v-1.26c0-.16-.13-.28-.28-.28H5.73Zm-.28,2.87v5.11c0,.16.13.28.28.28h11.56c.16,0,.28-.13.28-.28v-5.06l-5.73,3.95c-.19.13-.45.13-.65,0l-5.75-4Z"/></g></g></svg>'
                );
            }
        }
    }
}
