@mixin table-typeA {
    width: 100%;
    line-height: calc(22 / 14);
    // font-feature-settings: 'palt';
    border-collapse: collapse;
    th,
    td {
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.12em;
        line-height: calc(20 / 14);
        border: solid 1px #c8d3d6;
        vertical-align: middle;
        @include media-down(md) {
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
    th {
        padding: 22px 5px;
        white-space: nowrap;
        color: #fff;
        background-color: $primary;
        @include media-up(md) {
            width: 25%;
        }
        @include media-down(md) {
            padding: 10px 5px;
            // border-width: 0;
            // padding-bottom: 0;
        }
    }
    td {
        padding: 22px 10px;
        color: #000;
        background-color: #fafcff;
        @include media-down(md) {
            height: 57px;
            padding: 10px 2px;
        }
        &._left {
            padding: 22px 30px;
            text-align: left;
            @include media-down(md) {
                padding: 20px;
            }
        }
    }
    thead {
        th {
            padding: 10px;
            min-height: 61px;
        }
    }
    // tr:last-child th,
    // tr:last-child td {
    //     border-bottom-width: 0;
    // }
    &.col2 {
        th {
            width: calc(100% / 3);
        }
        @include media-down(md) {
            th {
                width: 27%;
            }
            td {
                width: 73%;
            }
        }
    }
    &.col3 {
        th,
        td {
            width: calc(100% / 3);
        }
        @include media-down(md) {
            th {
                width: 27%;
            }
            td {
                width: 36.5%;
            }
        }
    }
    &.col5 {
        th,
        td {
            width: 20%;
        }
    }
}
@mixin table-typeB {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: $secondary;
        letter-spacing: 0.12em;
        line-height: calc(20 / 14);
        vertical-align: middle;
        border: solid 1px #d6eaea;
    }
    th {
        padding: 12px 10px;
        background-color: #f4fbfd;
        &:first-child,
        &:nth-child(2) {
            width: 202px;
            @include media-down(md) {
                width: 25%;
                padding: 10px 5px;
            }
        }
    }
    td {
        padding: 16px 25px;
        @include media-down(md) {
            padding: 10px 5px;
            line-height: calc(17 / 13);
        }
        &.black {
            color: #000;
            @include media-down(md) {
                padding: 10px 25px;
                text-align: left;
            }
        }
    }
}
@mixin table-typeC {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: $secondary;
        letter-spacing: 0.12em;
        line-height: calc(20 / 14);
        vertical-align: middle;
        border: solid 1px #d6eaea;
    }
    th {
        padding: 12px 10px;
        background-color: #f4fbfd;
        &:first-child {
            width: 16%;
        }
        &:nth-child(2) {
            width: 52%;
        }
        &:nth-child(3),
        &:nth-child(4) {
            width: 16%;
        }
    }
    td {
        padding: 16px 15px 16px 25px;
        color: #000;
        line-height: calc(25 / 14);
        &:nth-child(2) {
            text-align: left;
        }
    }
    .red {
        color: #d22d26;
    }
    .orange {
        color: #e59a37;
    }
    .yellow {
        color: #eabe42;
    }
    .white {
        color: #000;
    }
    .green {
        color: #43964e;
    }
    .blue {
        color: #2b66b0;
    }
    .navy {
        color: #0209ae;
    }
}
@mixin table-typeD {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
        font-size: calc(13 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.12em;
        line-height: calc(20 / 13);
        vertical-align: middle;
        border: solid 1px #d6eaea;
    }
    thead {
        th {
            background-color: #339fd4;
            &:nth-child(n + 7) {
                background-color: #60af52;
            }
        }
    }
    tbody {
        th,
        td {
            height: 100px;
        }
        th {
            color: #339fd4;
            background-color: #f4fbfd;
            &:nth-child(n + 7) {
                color: #60af52;
                background-color: #eaf7e7;
            }
        }
    }
    th {
        width: calc(100% / 8);
        padding: 12px 10px;
        color: #fff;
    }
    td {
        padding: 13px 10px;
        color: #000;
        &:nth-child(n + 7) {
            border-color: #c9e2ca;
        }
    }
}
@mixin table-typeE {
    width: 100%;
    border-collapse: collapse;
    @include media-down(md) {
        width: 840px;
    }
    th,
    td {
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.12em;
        line-height: calc(20 / 14);
        vertical-align: middle;
        border: solid 1px #d6eaea;
    }
    th {
        padding: 10px 10px;
        color: $secondary;
        background-color: #f4fbfd;
        &:first-child {
            width: 28.5%;
        }
        &:nth-child(2),
        &:nth-child(3) {
            width: 11%;
        }
        &:nth-child(4) {
            width: 49.5%;
        }
    }
    td {
        padding: 13px 10px;
        color: $text-color;
        line-height: 1.8;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: normal;
        &:nth-child(4) {
            padding: 13px 17px;
            text-align: left;
        }
    }
}
@mixin table-base {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
        min-height: 175px;
        padding: 28px 23px;
        font-weight: bold;
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(27 / 14);
        vertical-align: middle;
        border: solid 1px #c8d3d6;
        @include media-down(sm) {
            letter-spacing: 0.1em;
            line-height: 1.4;
        }
    }
    th {
        width: 210px;
        font-size: calc(15 / 16 * $font-size-base);
        text-align: center;
        @include media-down(sm) {
            width: 78px;
            padding: 11px 5px;
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
    td {
        font-size: calc(14 / 16 * $font-size-base);
        @include media-down(sm) {
            padding: 11px 13px;
            font-size: calc(13 / 16 * $font-size-base);
        }
    }
    tr:nth-of-type(odd) {
        th,
        td {
            background-color: #f5f7fa;
        }
    }
}
