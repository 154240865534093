@mixin content-anchor-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 map-get($spacers, 5);
    margin: 50px auto 0;
    column-gap: 30px;
    row-gap: 13px;
    @include media-down(lg) {
        margin-bottom: 55px;
        column-gap: 25px;
    }
    @include media-down(md) {
        justify-content: flex-start;
        margin-top: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 3);
    }

    .anchor-link {
        transition: $transition-base;
        position: relative;
        padding: 9px 30px 9px 0;
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(14 / 16 * $font-size-base);
        color: $secondary;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        border-bottom: 1px $secondary solid;
        @include media-up(md) {
            // flex-grow: 1;
            // max-width: 141px;
        }
        @include media-down(md) {
            padding: 3px 15px 3px 0;
            font-size: calc(13 / 16 * $font-size-base);
            // width: calc(50% - 2.18rem * 0.5);
        }
        &::before {
            content: '';
            transform: rotate(90deg);
            position: absolute;
            right: 2px;
            top: 0;
            bottom: 0;
            width: 18px;
            height: 18px;
            margin: auto;
            background: svgUrlFunc(
                    '<svg id="icon-list-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#0a59a0;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
                )
                left top / 100% auto no-repeat;
             @include media-down(md) {
                width: 12px;
                height: 12px;
             }
        }

        &::after {
            content: '';
            width: 0;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: -1px;
            transition: all 0.5s;
            background-color: $primary;
        }

        &:hover {
            color: $primary;
            text-decoration: none;
            &::before {
                background-image: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.85 17.85"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}.cls-2{fill:#339fd4;stroke-width:0px;}</style></defs><g id="_デザイン"><circle class="cls-2" cx="8.92" cy="8.92" r="8.92"/><path class="cls-1" d="m7.96,6.59l2.54,2.33-2.54,2.33"/></g></svg>'
                )
            }

            &::after {
                width: 100%;
            }
        }

        @include media-down(md) {
            column-gap: 2.18rem;
        }
    }
}