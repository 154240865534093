.is--contact {
    #main > .element_mailform {
        max-width: 700px;
        @include media-up(sm) {
            margin-bottom: 8rem;
        }
        @include media-down(sm) {
            margin-bottom: 5rem;
        }
    }
    .rwd002-form-index__list {
        justify-content: center;
        margin: 0;
    }
    .rwd002-form-index__button {
        transition: $transition-base;
        width: 240px;
        height: 65px;
        padding: 0;
        font-feature-settings: normal;
        font-size: calc(18 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.12em;
        background-color: $secondary;
        border-radius: 0;
        @include media-down(md) {
            margin-bottom: 40px;
        }
        @include media-down(sm) {
            width: 100%;
        }
        &::after {
            display: none;
        }
        &:hover,
        &:focus {
            background-color: #003876;
        }
    }
}

.editor-template {
    .contact-head {
        &__notice {
            display: block;
            margin-bottom: 55px;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.12em;
            line-height: calc(30 / 18);
            @include media-down(md) {
                margin-bottom: 35px;
            }
        }
        &__title {
            margin-bottom: 35px;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            text-align: center;
            color: $secondary;
            letter-spacing: 0.12em;
            text-indent: 0.12em;
            @include media-down(md) {
                margin-bottom: 18px;
            }
        }
        &__btnwrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-bottom: 90px;
            @include media-down(md) {
                margin-bottom: 40px;
            }
        }
        &__btn {
            transition: $transition-base;
            width: 100%;
            max-width: 700px;
            @include media-down(sm) {
                width: 100%;
            }
            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
            }
            @media (hover: hover) {
                &:hover {
                    opacity: 0.8;
                }
            }
        }

        &__tel {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 0 12px;
            .tel {
                font-size: calc(40 / 16 * $font-size-base);
                font-family: 'Ubuntu', $font-family-sans-serif;
                font-weight: 700;
                color: $secondary !important;
                letter-spacing: 0.13em;
                text-indent: 0.13em;
                line-height: 1;
            }
            .tel:hover,
            .tel:focus {
                text-underline-offset: 0.15em;
                text-decoration-thickness: 0.05em;
            }
        }
        .opentime {
            display: block;
            margin-top: 0;
            margin-bottom: 100px;
            text-align: center !important;
            line-height: 1;
            @include media-down(sm) {
                margin-bottom: 45px;
            }
            ._text {
                position: relative;
                font-size: calc(14 / 16 * $font-size-base);
                font-weight: bold;
                color: $secondary;
                letter-spacing: 0.12em;
                text-indent: 0.12em;
                @include media-down(sm) {
                    font-size: calc(11 / 16 * $font-size-base);
                }
                &:not(:last-of-type) {
                    margin-right: 16px;
                    @include media-down(sm) {
                        margin-right: 14px;
                    }
                }
                &::before {
                    content: '';
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    margin-right: 2px;
                    background-color: $secondary;
                    border-radius: 50%;
                    @include media-down(sm) {
                        width: 7px;
                        height: 7px;
                    }
                }
            }
        }

        &__desc {
            max-width: 840px;
            margin-inline: auto;
            margin-bottom: 85px;
            font-size: calc(12 / 16 * $font-size-base);
            text-align: left !important;
            line-height: 1.8;
            letter-spacing: 0.12em;
            @include media-down(md) {
                margin-bottom: 30px;
            }
        }
    }
}

.entry.entry_formbanner {
    display: flex;
    justify-content: center;
    margin: 3rem 0;
    gap: 15px;
    @include media-down(sm) {
        padding: 0 20px;
    }
}

#main > .element_detail .entry_formbanner:after,
#main > .element_detail .entry_formbanner:before {
    display: none;
}

.entry_formbanner .element_blog_banner {
    padding: 0;
    margin: 0;
    @include media-up(sm) {
        width: calc((100% - 30px) / 3);
    }
}
