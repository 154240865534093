.rwd002-moduletitle {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 4);
    text-align: center;
    @include media-down(md) {
        margin-top: map-get($spacers, 4);
        margin-bottom: map-get($spacers, 4);
    }
    &__inner {
        @include c-module-title;
        padding-left: 1.6875rem;
        padding-right: 1.6875rem;
    }
}

.moduletitle {
    position: relative;
    height: 280px;
    padding-top: 45px;
    background: url(/assets/img/webp/pho-headline-back@2x.webp) no-repeat top
        center / auto 100%;
    @include media-down(sm) {
        height: 157px;
        padding-top: 25px;
        background-image: url(/assets/img/webp/pho-headline-back_sp@2x.webp);
        background-position: left bottom;
        background-size: 100% auto;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 50%;
            background-color: #00a2d9;
        }
    }
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 28px;
    //     left: 0;
    //     right: 0;
    //     margin: auto;
    //     width: 1384px;
    //     height: 230px;
    //     background: url(/assets/img/webp/pho-headline-parts@2x.webp) no-repeat top center / 100% auto;
    // }
    &__parts {
        position: absolute;
        top: 28px;
        left: 0;
        right: 0;
        margin: auto;
        @include media-down(md) {
            right: inherit;
        }
        @include media-down(sm) {
            top: 10px;
        }
        img {
            max-width: 100%;
            height: auto;
            @include media-down(md) {
                max-width: 95%;
            }
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .icon {
        // min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-down(sm) {
            // min-height: 28px;
        }
        svg {
            fill: #fff;
            @include media-down(sm) {
                width: auto;
                max-height: 100%;
            }
        }
    }
    .ja {
        margin-top: 16px;
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(29 / 16 * $font-size-base);
        letter-spacing: 0.13em;
        color: #fff;
        @include media-down(md) {
            font-size: calc(22 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            margin-top: 7px;
        }
    }
    .eng {
        margin-top: 14px;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: 'Ubuntu', $font-family-sans-serif;
        font-weight: 700;
        color: #005ba5;
        letter-spacing: 0.22em;
        @include media-down(sm) {
            margin-top: 11px;
        }
    }
}
.is--guide {
    .moduletitle {
        .icon {
            width: 34px;
            height: 23px;
        }
    }
}
.is--contact {
    .moduletitle {
        .icon {
            width: 30px;
            height: 21px;
        }
    }
}
.is--privacy,.is--sitemap {
    .moduletitle {
        @include media-down(sm) {
            padding-top: 34px;
        }
    }
}