.is--facility.is--list {
    .rwd002-list-content {
        @include media-down(md) {
            margin-top: 20px;
        }
    }
    .rwd002-list-content__inner {
        max-width: 940px;
    }
    .rwd002-list-content__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 65px 110px;
        margin: 0;
        padding: 0;
        @include media-down(lg) {
            gap: 50px 70px;
        }
        @include media-down(md) {
            gap: 50px;
        }
        @include media-down(sm) {
            display: block;
        }
    }
    .rwd002-list-content__item {
        width: 100%;
        margin: 0;
        padding: 0;
        @include media-down(sm) {
            &:not(:last-of-type) {
                margin-bottom: 50px;
            }
        }
    }
    .rwd002-list-content__link {
        display: block;
        @media (hover: hover) {
            &:hover,
            &:focus {
                button {
                    color: $primary;
                }
            }
        }
    }
    .rwd002-list-content__thumb {
        aspect-ratio: 415 / 273;
        width: 100%;
        margin: 0 0 30px;
        border-radius: 10px;
        @include media-down(sm) {
            margin-bottom: 15px;
        }
    }
    .rwd002-list-content__body {
        .title {
            @include c-sub-title();
            margin-bottom: 12px;
            padding-bottom: 10px;
            background: url(/assets/img/webp/line-yoko-blue@2x.webp) repeat-x 0 100%/auto 1.5px;
        }
        .desc {
            margin-bottom: 20px;
            font-size: calc(15 / 16 * $font-size-base);
            font-weight: bold;
            font-feature-settings: normal;
            color: $text-color;
            letter-spacing: 0.12em;
            line-height: calc(32 / 15);
            @include media-down(sm) {
                margin-bottom: 12px;
                line-height: calc(28 / 15);
            }
        }
    }
    .rwd002-list-content__btnwrap {
        justify-content: flex-start;
        button {
            @include c-more-button();
        }
    }
}
