@mixin c-module-title() {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.75em;
    font-weight: normal;
    letter-spacing: 0.22em;
    text-indent: 0.22em;
    font-size: $h2-font-size;
    @include media-down(lg) {
        font-size: 1.875rem;
    }
    @include media-down(md) {
        font-size: $h4-font-size;
    }

    span {
        font-family: 'Roboto', $font-family-sans-serif;
        font-weight: $font-weight-normal;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        display: block;
        width: 82px;
        height: 1px;
        margin: auto;
        background: $primary;
    }
}

@mixin c-title-primary($theme_en: $primary, $theme: $tertiary) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $text-color;
    text-align: center;
    .icon {
        margin-bottom: 0.25em;
        fill: $secondary;
    }
    strong {
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(29 / 16 * $font-size-base);
        letter-spacing: 0.13em;
        color: $text-color;
        text-indent: 0.13em;
        @include media-down(md) {
            font-size: calc(24 / 16 * $font-size-base);
        }
    }
    small {
        margin-top: 0.5em;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: 'Ubuntu', $font-family-sans-serif;
        // font-weight: 500;
        font-weight: 700;
        color: $secondary;
        letter-spacing: 0.22em;
        text-indent: 0.22em;
    }
}

@mixin c-sub-title($align: flex-start) {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 15px;
    font-family: 'Zen Maru Gothic', $font-family-sans-serif;
    font-weight: bold;
    font-size: calc(20 / 16 * $font-size-base);
    color: $secondary;
    text-indent: 0.12em;
    letter-spacing: 0.12em;
    line-height: calc(28 / 17);
    @include media-down(md) {
        font-size: calc(18 / 16 * $font-size-base);
    }
    &::before {
        content: '';
        position: absolute;
        top: 0.7rem;
        left: 0;
        display: inline-block;
        flex-shrink: 0;
        width: 11px;
        height: 11px;
        margin-right: 3px;
        background-color: $secondary;
        border-radius: 50%;
        @include media-down(md) {
            top: 0.6rem;
        }
    }
}
@mixin c-block-title($align: flex-start) {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    color: $primary;
    @if $align == 'center' {
        text-align: center;
    }
    @include media-down(md) {
        margin-bottom: 20px;
    }
    .eng {
        font-size: calc(53 / 16 * $font-size-base);
        font-family: 'Montserrat', $font-family-sans-serif;
        font-weight: 500;
        letter-spacing: 0.15em;
        line-height: 1;
        @if $align == 'center' {
            text-indent: 0.14em;
        }
        @include media-down(md) {
            font-size: calc(40 / 16 * $font-size-base);
        }
    }
    .ja {
        display: flex;
        align-items: center;
        margin-top: 12px;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        letter-spacing: 0.13em;
        @if $align == 'center' {
            justify-content: center;
            text-indent: 0.2em;
        }
        @include media-down(md) {
            margin-top: 10px;
        }
    }
    .svgicon {
        fill: $secondary;
        margin-right: 7px;
    }
}

@mixin c-local-title($align: flex-start) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 30px;
    font-family: 'Zen Maru Gothic', $font-family-sans-serif;
    font-weight: bold;
    font-size: calc(25 / 16 * $font-size-base);
    text-align: center;
    color: #00a2d9;
    letter-spacing: 0.12em;
    text-indent: 0.12em;
    @include media-down(md) {
        margin-bottom: 20px;
        font-size: calc(22 / 16 * $font-size-base);
        line-height: calc(29 / 22);
    }
    @if $align == 'center' {
        align-items: center;
    }
    .icon {
        margin-bottom: 12px;
        @include media-down(sm) {
            margin-bottom: 7px;
        }
    }
}

@mixin c-round-title($align: flex-start) {
    width: max-content;
    margin-bottom: 30px;
    padding: 7px 25px;
    font-weight: bold;
    font-size: calc(18 / 16 * $font-size-base);
    color: #fff;
    letter-spacing: 0.12em;
    text-indent: 0.12em;
    background-color: #006cb9;
    border-radius: 50px;
    @include media-down(md) {
        margin-bottom: 20px;
        font-size: calc(16 / 16 * $font-size-base);
        line-height: calc(23 / 16);
    }
    @include media-down(sm) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 45px;
        padding: 13px 15px;
        text-align: center;
    }
    @if $align == 'center' {
        align-items: center;
    }
}