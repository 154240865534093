.program {
    &-detail {
        width: 100%;
        max-width: 840px;
        margin-inline: auto;
    }
    &__block {
        margin-bottom: 60px;
    }
    &__title {
        @include c-round-title();
    }
    &__subTitle {
        @include c-sub-title();
        &._single {
            padding-left: 0;
            &::before {
                display: none;
            }
        }
    }
    &__text {
        margin-bottom: 25px;
        font-size: calc(15 / 16 * $font-size-base);
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(md) {
            line-height: calc(28 / 15);
        }
        &._bold {
            font-weight: bold;
        }
        &._indent {
            // padding-left: 1em;
            // text-indent: -1em;
            @include media-down(sm) {
                font-size: calc(12 / 16 * $font-size-base);
                padding-left: 0;
                text-indent: 0;
                line-height: calc(19 / 12);
            }
        }
        &._narrowly {
            letter-spacing: 0.09em;
        }
        &-right {
            font-size: calc(14 / 16 * $font-size-base);
            text-align: right;
            color: $text-color;
            letter-spacing: 0.12em;
            @include media-down(md) {
                font-size: calc(12 / 16 * $font-size-base);
            }
            &._bold {
                font-weight: bold;
            }
        }
        &-center {
            margin: 0;
            font-size: calc(14 / 16 * $font-size-base);
            text-align: center;
            color: $text-color;
            letter-spacing: 0.12em;
            @include media-down(md) {
                font-size: calc(12 / 16 * $font-size-base);
                text-align: left;
            }
            &._bold {
                font-weight: bold;
            }
        }
    }
    &__list {
        margin-bottom: 0;
        padding: 0;
        list-style-position: inside;
        &__item {
            padding: 19px 0 19px 1.5em;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            text-indent: -1.5em;
            color: $secondary;
            letter-spacing: 0.12em;
            line-height: calc(27 / 18);
            &:not(:last-of-type) {
                background: url(/assets/img/webp/line-yoko-blue@2x.webp)
                    repeat-x bottom left / auto 1.5px;
            }
        }
    }
    &__experiences {
        margin-bottom: 30px;
        @include media-up(md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 60px;
        }
        @include media-down(sm) {
            margin-bottom: 20px;
        }
        &__item {
            @include media-down(md) {
                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }
            }
            ._title {
                margin-bottom: 15px;
                padding-bottom: 12px;
                font-size: calc(18 / 16 * $font-size-base);
                font-weight: bold;
                color: $secondary;
                letter-spacing: 0.12em;
                background: url(/assets/img/webp/line-yoko-blue@2x.webp)
                    repeat-x bottom left / auto 1.5px;
            }
            ._text {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                font-weight: bold;
                color: $text-color;
                letter-spacing: 0.12em;
                line-height: calc(32 / 15);
                @include media-down(md) {
                    line-height: calc(28 / 15);
                }
            }
        }
    }
    &__goods {
        padding: 35px 25px;
        background-color: #f5f7fa;
        border-radius: 10px;
        @include media-down(sm) {
            padding: 22px 25px;
        }
        &__list {
            @include e-list-dot();
        }
    }
    &__flow {
        margin-bottom: 30px;
        @include media-down(sm) {
            margin-bottom: 10px;
            padding: 0 15px;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-inline: auto;
        }
    }
    &__qa {
        margin-bottom: 30px;
        padding-left: 12px;
        padding-bottom: 17px;
        background: url(/assets/img/webp/line-yoko-blue@2x.webp) repeat-x bottom
            left / auto 1.5px;
        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 28px;
            height: 28px;
            font-weight: bold;
            margin-right: 12px;
            color: #fff;
            border-radius: 50%;
        }
        p {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            font-weight: bold;
            color: #373738;
            letter-spacing: 0.12em;
            line-height: calc(32 / 15);
        }
        .question,
        .answer {
            display: flex;
        }
        .question {
            margin-bottom: 17px;
            .icon {
                background-color: #373738;
            }
        }
        .answer {
            .icon {
                background-color: $primary;
            }
        }
    }
    &__table-wrap {
        @include media-down(sm) {
            position: relative;
            &::before {
                content: '右にscroll';
                display: block;
                margin-left: auto;
                margin-bottom: 0.8rem;
                padding-right: 40px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.33 7.06"><defs><style>.cls-1{fill:none;stroke:#373738;stroke-linecap:round;stroke-linejoin:round;}</style></defs><g id="_デザイン"><polyline class="cls-1" points=".5 6.56 37.83 6.56 32.37 .5"/></g></svg>'
                    )
                    100%/38px 7px no-repeat;
                font-size: calc(12 / 16 * $font-size-base);
                letter-spacing: 0.13em;
                text-align: right;
                animation: scroll_arrow 3s ease-in-out infinite forwards;
            }
        }
    }
    &__table-inner {
        @include media-down(sm) {
            overflow-x: auto;
            white-space: nowrap;
        }
        @include media-down(sm) {
            .program__table {
                width: 600px;
            }
            .program__tableC,
            .program__tableD {
                width: 840px;
            }
        }
    }
    &__table {
        @include table-typeA;
    }
    &__tableB {
        @include table-typeB;
    }
    &__tableC {
        @include table-typeC;
    }
    &__tableD {
        @include table-typeD;
    }
    &__tableE {
        @include table-typeE;
        .star {
            img {
                width: auto;
                height: 13px;
            }
        }
    }
    &__btnwrap2 {
        display: flex;
        justify-content: center;
        margin-top: 45px;
        @include media-down(md) {
            margin-top: 30px;
        }
    }
    &__ghostBtn {
        @include c-ghost-button();
        max-width: 626px;
        &::after {
            transform: rotate(0);
            margin-left: 15px;
        }
        &.sp-left {
            @include media-down(sm) {
                justify-content: flex-start;
                padding-left: 22px;
                text-align: left;
            }
        }
    }
}
.program-bnr {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
        transition: $transition-base;
        display: block;
        &:not(:last-of-type) {
            margin-bottom: 27px;
            @include media-down(md) {
                margin-bottom: 15px;
            }
        }
        @media (hover: hover) {
            &:hover,
            &:focus {
                opacity: 0.8;
            }
        }
    }
    img {
        display: block;
        max-width: 100%;
        height: auto;
    }
    .rwd002-block-freearea {
        margin: 0;
        padding: 0;
    }
}
.is--program.is--list {
    .rwd002-block-content__inner {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        max-width: 940px;
        gap: 15px;
        @include media-down(md) {
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
        }
        .cate {
            margin: 0;
            padding-inline: 5px;
            font-family: 'Zen Maru Gothic', $font-family-sans-serif;
            font-weight: bold;
            font-size: calc(14 / 16 * $font-size-base);
            color: $secondary;
            letter-spacing: 0.12em;
            text-indent: 0.12em;
            border-color: $secondary !important;
            border-radius: 50px;
            @include media-down(lg) {
                font-size: calc(12 / 16 * $font-size-base);
            }
            &.current {
                color: #fff;
                background-color: $secondary;
            }
            @media (hover: hover) {
                &:hover,
                &:focus {
                    color: #fff;
                    background-color: $secondary;
                }
            }
        }
    }
    .rwd002-list-content {
        @include media-down(md) {
            margin-top: 20px;
        }
    }
    .rwd002-list-content__inner {
        max-width: 940px;
    }
    .rwd002-list-content__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 65px 110px;
        margin: 0 0 65px;
        padding: 0;
        @include media-down(lg) {
            gap: 50px 70px;
        }
        @include media-down(md) {
            gap: 50px;
        }
        @include media-down(sm) {
            display: block;
            margin-bottom: 60px;
        }
    }
    .rwd002-list-content__item {
        width: 100%;
        margin: 0;
        padding: 0;
        @include media-down(sm) {
            &:not(:last-of-type) {
                margin-bottom: 45px;
            }
        }
    }
    .rwd002-list-content__link {
        display: block;
        @media (hover: hover) {
            &:hover,
            &:focus {
                button {
                    color: $primary;
                }
            }
        }
    }
    .rwd002-list-content__catName {
        font-feature-settings: normal;
        border: none;
        @include media-down(md) {
            margin-bottom: 17px;
        }
        a {
            position: relative;
            display: flex;
            align-items: center;
            width: max-content;
            height: 46px;
            padding: 7px 45px 8px 35px;
            font-family: 'Zen Maru Gothic', $font-family-sans-serif;
            font-weight: bold;
            font-size: calc(20 / 16 * $font-size-base);
            letter-spacing: 0.12em;
            text-indent: 0.12em;
            color: #fff !important;
            line-height: 1;
            border: none;
            @include media-down(md) {
                height: 34px;
                padding: 2px 29px 3px 19px;
                font-size: calc(18 / 16 * $font-size-base);
            }
            &::after {
                position: absolute;
                content: '';
                width: 0px;
                height: 0px;
                z-index: 1;
                top: 0;
                right: 0;
                border-width: 23px 15px 23px 0px;
                border-color: transparent #fff transparent transparent;
                border-style: solid;
                @include media-down(md) {
                    border-width: 17px 10px 17px 0px;
                }
            }
        }
        &.cat-1 {
            a {
                background-color: #eb71aa;
            }
        }
        &.cat-2 {
            a {
                background-color: #eabe42;
            }
        }
        &.cat-3 {
            a {
                background-color: #2eaf6b;
            }
        }
        &.cat-4 {
            a {
                background-color: #e97c31;
            }
        }
    }
    .rwd002-list-content__thumb {
        aspect-ratio: 415 / 252;
        width: 100%;
        margin: 0 0 30px;
        border-radius: 10px;
        @include media-down(sm) {
            margin-bottom: 15px;
        }
    }
    .rwd002-list-content__body {
        .title {
            @include c-sub-title();
            margin-bottom: 12px;
            padding-bottom: 10px;
            background: url(/assets/img/webp/line-yoko-blue@2x.webp) repeat-x 0
                100% / auto 1.5px;
        }
        .desc {
            margin-bottom: 10px;
            font-size: calc(18 / 16 * $font-size-base);
            font-weight: bold;
            font-feature-settings: normal;
            color: $text-color;
            letter-spacing: 0.15em;
            line-height: calc(25 / 17);
            @include media-down(md) {
                font-size: calc(17 / 16 * $font-size-base);
                letter-spacing: 0.1em;
            }
        }
    }
    .body-first {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; //3点リーダを表示する行数を指定
        overflow: hidden;
        margin-bottom: 20px;
        font-size: calc(15 / 16 * $font-size-base);
        font-weight: bold;
        font-feature-settings: normal;
        color: $text-color;
        letter-spacing: 0.12em;
        line-height: calc(32 / 15);
        @include media-down(sm) {
            margin-bottom: 12px;
            -webkit-line-clamp: 3;
            line-height: calc(27 / 15);
        }
    }
    .rwd002-list-content__btnwrap {
        justify-content: flex-start;
        button {
            @include c-more-button();
        }
    }
}
.is--program.is--detail {
    .rwd002-detail {
        @include media-down(sm) {
            margin-top: 20px;
        }
    }
    .rwd002-block.is-head {
        display: none;
    }
    .rwd002-detail__head,
    .rwd002-detail__entry {
        width: 100%;
        max-width: 840px;
        font-feature-settings: normal;
    }
    .rwd002-detail__category {
        display: block;
        width: max-content;
        min-width: 220px;
        margin-inline: auto;
        margin-bottom: 20px !important;
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(14 / 16 * $font-size-base);
        color: $secondary;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        border-width: 2px;
        border-radius: 50px;
        @include media-down(md) {
            margin-bottom: 10px !important;
            font-size: calc(14 / 16 * $font-size-base);
        }
        @media (hover: hover) {
            &:hover {
                color: #fff;
            }
        }
    }
    .rwd002-detail__title {
        margin-bottom: 35px;
        padding: 0;
        font-family: 'Zen Maru Gothic', $font-family-sans-serif;
        font-weight: bold;
        font-size: calc(42 / 16 * $font-size-base);
        text-align: center;
        color: $secondary;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        line-height: 1;
        border: none;
        @include media-down(md) {
            font-size: calc(24 / 16 * $font-size-base);
        }
        @include media-down(sm) {
            margin-bottom: 20px;
        }
    }
    .rwd002-detail__image {
        margin-bottom: 25px;
        @include media-down(sm) {
            margin-bottom: 10px;
        }
        a {
            display: block;
            aspect-ratio: 840 / 450;
            overflow: hidden;
            border-radius: 10px;
        }
        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .rwd002-detail__desc {
        margin-bottom: 15px;
        font-size: calc(28 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: $text-color;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        line-height: calc(47 / 28);
        @include media-down(md) {
            margin-bottom: 10px;
            font-size: calc(20 / 16 * $font-size-base);
            text-align: left;
            letter-spacing: 0.11em;
        }
    }
    .rwd002-detail__lead {
        margin-bottom: 60px;
        font-size: calc(18 / 16 * $font-size-base);
        font-weight: bold;
        text-align: center;
        color: $text-color;
        letter-spacing: 0.12em;
        text-indent: 0.12em;
        line-height: calc(32 / 18);
        @include media-down(md) {
            margin-bottom: 45px;
            font-size: calc(15 / 16 * $font-size-base);
            text-align: left;
        }
    }
}
